import { useShowPopup, useThemeParams, MainButton, BackButton } from '@vkruglikov/react-telegram-web-app';
import { ConfigProvider, theme, Typography, Descriptions, Skeleton, Button, Tag, Table } from 'antd';
import { useLocation, useParams, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react'
import axios from 'axios';
import dateFormat from 'dateformat';

const columns = (id) => [
	{
	  title: 'id',
	  dataIndex: 'memberId',
	  key: 'memberId',
      render: (text) => {
        if(text != id)
        {
            return <Link to={"/member/" + Number(text)}>{text}</Link>
        }
        return text;
      }
	},
	{
	  title: 'toId',
	  dataIndex: 'memberToId',
	  key: 'memberToId',
      render: (text) => {
        if(text != id)
        {
            return <Link to={"/member/" + Number(text)}>{text}</Link>
        }
        return text;
      }
	},
	{
	  title: 'Действие',
	  dataIndex: 'action',
	  key: 'action',
	  render: (text) => (<Tag key={text}>
		{text.toUpperCase()}
	  </Tag>)
	},
	{
		title: 'Параметр',
		dataIndex: 'parameter',
		key: 'parameter',
	},
	{
		title: 'Дата',
		dataIndex: 'createdAt',
		key: 'createdAt',
        render: (date) => dateFormat(date, "dd.mm HH:MM")
	},
  ];

function MemberLog() {
	const showPopup = useShowPopup();
	const [colorScheme, themeParams] = useThemeParams();
    const { id } = useParams();
	const navigate = useNavigate();

	const [logsData, setLogsData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const logs = useMemo(() => {
		if(logsData)
		{
			return logsData.reduce((acc, log) => {
				acc.push({
					memberId: log.memberId,
					memberToId: log.memberToId,
					action: log.action,
					parameter: log.parameter,
					createdAt: log.createdAt
				})
				return acc;
			}, [])
		}
		else
			return null;
	}, [logsData])

	useEffect(() => {
		const fetchData = () => {
            console.log(id)
			axios.get("/member/logs", { params: {
				id: id
			}})
			.then((res) => {
				const data = res.data;
				setLogsData(data.logs);
				setIsLoading(false);
			})
			.catch((error) => {
				console.log(error)
				showPopup({
					message: error.response.data.error.message
				})
			})
		}
		fetchData()
	}, [])

	return (
		<div>
			<ConfigProvider
				theme={
					themeParams.text_color
					? {
						algorithm:
							colorScheme === 'dark'
							? theme.darkAlgorithm
							: theme.defaultAlgorithm,
						token: {
							colorText: themeParams.text_color,
							colorPrimary: themeParams.button_color,
							colorBgBase: themeParams.bg_color,
                            colorBgContainer: themeParams.bg_color
						},
						}
					: undefined
				}
			>
                <Typography.Title
                level={2}
                >
                    Логи участника №{id}
                </Typography.Title>
				{logs && <Table columns={columns(id)} dataSource={logs} pagination={false} loading={isLoading} rowKey={(record) => {
                    return "log_" + record.action + "_" + record.createdAt
                }}/>}
				<BackButton onClick={() => navigate(-1)} />;
			</ConfigProvider>
		</div>
	);
}

export default MemberLog;
