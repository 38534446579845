import { MainButton, useShowPopup, useThemeParams, useExpand, useScanQrPopup } from '@vkruglikov/react-telegram-web-app';
import { ConfigProvider, theme, Typography, Input, List, Empty } from 'antd';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import axios from 'axios';

const { Search } = Input;

function Main() {
	const showPopup = useShowPopup();
	const [colorScheme, themeParams] = useThemeParams();
	// const [isExpanded, expand] = useExpand();
	const [showQrPopup, closeQrPopup] = useScanQrPopup();
	const [searchResults, setSearchResults] = useState([])
	const [searchQuery, setSearchQuery] = useState("")
	const [searchLoading, setSearchLoading] = useState(false)
    const navigate = useNavigate();

	// useEffect(() => {
	// 	if(!isExpanded)
	// 		expand()
	// }, [])

	function searchMember(e) {
		const fetchData = () => {
			setSearchLoading(true)
			axios.get("/member/search", { params: { query: encodeURIComponent(e.target.value) } })
			.then((res) => {
				const data = res.data;
				setSearchResults(data.members)
				setSearchLoading(false)
			})
			.catch((error) => {
				console.log(error)
				showPopup({
					message: error.response.data.error.message
				})
				setSearchLoading(false)
			})
		}
		setSearchQuery(e.target.value)
		if(!e.target.value)
		{
			setSearchResults([])
		}
		if (e.target.value.length > 2) fetchData()
	}

	return (
		<div>
			<ConfigProvider
				theme={
					themeParams.text_color
					? {
						algorithm:
							colorScheme === 'dark'
							? theme.darkAlgorithm
							: theme.defaultAlgorithm,
						token: {
							colorText: themeParams.text_color,
							colorPrimary: themeParams.button_color,
							colorBgBase: themeParams.bg_color,
						},
						}
					: undefined
				}
			>
                <Typography.Title
                level={2}
                >
                    Отсканируйте QR-код участника
                </Typography.Title>
				<Typography.Title
                level={4}
                >
                    или
                </Typography.Title>
				<Search placeholder="Начните вводить ФИО или первые буквы: Г П Д" loading={searchLoading} value={searchQuery} onChange={searchMember} />
				{searchResults &&
					<List
						size="small"
						dataSource={searchResults}
						loading={searchLoading}
						renderItem={(item) => <List.Item onClick={() => navigate("/member/"+item.id, { state: { fromQR: false } })}>{item.surname} {item.name} {item.last_name || "[без отчества]"} | {item.academic_group}</List.Item>}
					/>
				}
				<MainButton
					text="Сканировать проходку"
					onClick={() =>
						showQrPopup(
						{
							text: 'QR код участника (Проходка)',
						},
						string => {
							closeQrPopup();
                            if(string.startsWith("team"))
							{
								const [id, captain_id, createdAt] = string.replace('team','').split("_")
								navigate("/team/"+id, { state: { captain_id: captain_id, createdAt: createdAt, fromQR: true } })
							}
							else
							{
								const [id, telegram_id, createdAt] = string.split("_")
								navigate("/member/"+id, { state: { telegram_id: telegram_id, createdAt: createdAt, fromQR: true } })
							}
						},
					)}
				/>
			</ConfigProvider>
		</div>
	);
}

export default Main;
