import { useShowPopup, useThemeParams, MainButton, BackButton } from '@vkruglikov/react-telegram-web-app';
import { ConfigProvider, theme, Descriptions, Skeleton, Button, Space, Select } from 'antd';
import { useLocation, useParams, useNavigate } from 'react-router';
import React, { useEffect, useMemo, useState, useRef } from 'react'
import axios from 'axios';

function Member() {
	const showPopup = useShowPopup();
	const [colorScheme, themeParams] = useThemeParams();
    const { id } = useParams();
	const navigate = useNavigate();
    const { state = {} } = useLocation();
	const { telegram_id, createdAt, fromQR } = state || {}

	const teamNames = useRef(null)
	const [member, setMember] = useState(null);
	const [button, setButton] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const items = useMemo(() => {
		if(!member) return null;
		if(member.team)
		{
			return [
				{
					key: '1',
					label: 'id',
					children: member.id,
				},
				{
					key: '2',
					label: 'ФИО',
					children: `${member.surname} ${member.name} ${member.last_name}`,
				},
				{
					key: '3',
					label: 'Telegram',
					children: member.telegram_username ? `@${member.telegram_username}` : member.telegram_id,
				},
				{
					key: '4',
					label: 'Группа',
					children: member.academic_group,
				},
				{
					key: '5',
					label: 'Телефон',
					children: `+${member.phone}`,
				},
				{
					key: '6',
				},
				{
					key: '7',
					label: 'Команда',
					children: `${member.team.title || "Нет"} (№${member.team.id || 0})`,
				},
				{
					key: '8',
					label: 'Капитан',
					children: member.team && member.team.captain === member.id ? "Да" : "Нет",
				},
				// {
				// 	key: '9',
				// 	label: 'Роль',
				// 	children: member.role ? roleNames[member.role] : "Нет",
				// },
				{
					key: '10',
					label: 'Допущена',
					children: member.team.approved !== null ? (member.team.approved === 1 ? <span style={{ color: "green" }}>Да</span> : <span style={{ color: "red" }}>Проверена/пересмотр</span>) : <span style={{ color: "red" }}>Не проверена</span>,
				},
				{
					key: '11',
					label: 'Большая команда',
					children: member.globalTeamId ? teamNames.current[member.globalTeamId] + " [" + member.globalTeamId + "]" : "Нет",
				},
				{
					key: '12',
					label: 'Голос в опросе',
					children: member.will_arrive_finally > -1 ? member.will_arrive_finally : "Не ответил",
				},
			]
		}
		else if(!member.team)
		{
			return [
				{
					key: '1',
					label: 'id',
					children: member.id,
				},
				{
					key: '2',
					label: 'ФИО',
					children: `${member.surname} ${member.name} ${member.last_name}`,
				},
				{
					key: '3',
					label: 'Telegram',
					children: member.telegram_username ? `@${member.telegram_username}` : member.telegram_id,
				},
				{
					key: '4',
					label: 'Группа',
					children: member.academic_group,
				},
				{
					key: '5',
					label: 'Телефон',
					children: `+${member.phone}`,
				},
				{
					key: '6',
				},
				{
					key: '7',
					label: 'Большая команда',
					children: member.globalTeamId ? teamNames.current[member.globalTeamId] + " [" + member.globalTeamId + "]" : "Нет",
				},
				{
					key: '8',
					label: 'Голос в опросе',
					children: member.will_arrive_finally > -1 ? member.will_arrive_finally : "Не ответил",
				},
			]	
		}
	}, [member])

	useEffect(() => {
		const fetchData = () => {
			let params;
			if(fromQR)
			{
				params = {
					id: id,
					telegram_id: telegram_id,
					createdAt: createdAt,
					fromQR: 1
				}
			}
			else
			{
				params = {
					id: id,
					fromQR: 0
				}		
			}
			axios.get("/member", { params: params })
			.then((res) => {
				const data = res.data;
				if(data.member.globalTeamId === null)
					data.member.globalTeamId = 0;
				if(data.member.will_arrive_finally === null)
					data.member.will_arrive_finally = -1

				setMember(data.member);
				setButton(data.button);
				teamNames.current = data.teamNames;
				setIsLoading(false);
			})
			.catch((error) => {
				console.log(error)
				showPopup({
					message: error.response.data.error.message
				})
			})
		}
		fetchData()
	}, [])

	const handleButtonScan = (action = "enter") => {
		// if(member.globalTeamId === 0 && action === "enter")
		// 	return showPopup({
		// 		message: "У участника не выбрана команда. Сначала назначьте команду",
		// 		buttons: [
		// 			{"type":"default", "id": "buttonStay", "text": "ОК" }
		// 		]
		// 	})
		axios.post("/member/scan/" + action, { id: id })
		.then((res) => {
			const data = res.data
			if(data.status == "ok")
			{
				setButton(null)
				showPopup({
					message: data.text,
					buttons: [
						{"type":"default", "id": "buttonStay", "text": "Остаться" },
						{"type":"default", "id": "buttonOk", "text": "Дальше" }
					]
				})
				.then((popupResponse) => {
					if(popupResponse === "buttonOk")
					{
						navigate("/")
					}
				})
			}
		})
		.catch((error) => {
			console.log(error)
			showPopup({
				message: error.response.data.error.message
			})
		})
	}

	const handleChangeSelect = (value) => {
		setIsLoading(true)
		axios.post("/member/change/globalTeam", { id: id, value: value })
		.then((res) => {
			const data = res.data
			if(data.status == "ok")
			{
				setIsLoading(false)
				setMember({ ...member, globalTeamId: Number(value) })
				showPopup({
					message: data.text,
					buttons: [
						{"type":"default", "id": "buttonStay", "text": "ОК" }
					]
				})
			}
		})
		.catch((error) => {
			console.log(error)
			showPopup({
				message: error.response.data.error.message
			})
		})
	};

	return (
		<div>
			<ConfigProvider
				theme={
					themeParams.text_color
					? {
						algorithm:
							colorScheme === 'dark'
							? theme.darkAlgorithm
							: theme.defaultAlgorithm,
						token: {
							colorText: themeParams.text_color,
							colorPrimary: themeParams.button_color,
							colorBgBase: themeParams.bg_color,
							bodySortBg: themeParams.bg_color
						},
						}
					: undefined
				}
			>
				{isLoading && <Skeleton active />}
				{items && <Descriptions items={items} bordered size='medium'/>}
				{member &&
					<Space direction="vertical" style={{ width: '100%', marginTop: 10 }}>
						<Select options={[
							{ value: 0, label: <span>{teamNames.current[0]} [0]</span> },
							{ value: 1, label: <span>{teamNames.current[1]} [1]</span> },
							{ value: 2, label: <span>{teamNames.current[2]} [2]</span> },
							{ value: 3, label: <span>{teamNames.current[3]} [3]</span> },
							{ value: 4, label: <span>{teamNames.current[4]} [4]</span> },
							{ value: 5, label: <span>{teamNames.current[5]} [5]</span> }
							]} 
							value={member.globalTeamId}
							onChange={handleChangeSelect}
							style={{ width: '100%' }}
							size="large"
							/>
					</Space>
				}
				{member && 
					<Space direction="vertical" style={{ width: '100%', marginTop: 10 }}>
						<Button type="dashed" block size='large' onClick={() => navigate("/member/logs/" + id)}>
							Логи участника
						</Button>
					</Space>
				}
				{member && button && button.skanOnEnter &&
					<MainButton
						text="Скан на вход"
						disabled={!isLoading || member.team.approved == 0 || !member.team.approved}
						onClick={() => handleButtonScan("enter")}
					/>		
				}
				{member && button && button.skanOnLeave &&
					<MainButton
						text="Скан на выход"
						disabled={!isLoading || member.team.approved == 0 || !member.team.approved}
						onClick={() => handleButtonScan("leave")}
					/>				
				}
				<BackButton onClick={() => navigate(-1)} />
			</ConfigProvider>
		</div>
	);
}

export default Member;
