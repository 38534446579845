import { useShowPopup, useThemeParams, BackButton } from '@vkruglikov/react-telegram-web-app';
import { ConfigProvider, theme, Table, Descriptions, Skeleton, Space } from 'antd';
import { Link } from 'react-router-dom'
import { useLocation, useParams, useNavigate } from 'react-router';
import React, { useEffect, useMemo, useState } from 'react'
import axios from 'axios';
import dateFormat from 'dateformat';
import roleNames from './roles'

const columns = (id) => [
	{
	  title: 'id',
	  dataIndex: 'id',
	  key: 'id',
      render: (text) => {
        return <Link to={"/member/" + Number(text)}>{text}</Link>
      }
	},
    {
        title: 'Фамилия',
        dataIndex: 'surname',
        key: 'surname',
    },
	{
	  title: 'И.',
	  dataIndex: 'name',
	  key: 'name',
      render: (text) => text.slice(0, 1) + "."
	},
    {
        title: 'О.',
        dataIndex: 'last_name',
        key: 'last_name',
        render: (text) => text.slice(0, 1) + "."
    },
    {
        title: 'Роль',
        dataIndex: 'role',
        key: 'role',
        render: (text) => {
            if(text) 
                return roleNames[text];
            return "Нет"
        }
    },
  ];

function Team() {
	const showPopup = useShowPopup();
	const [colorScheme, themeParams] = useThemeParams();
    const { id } = useParams();
	const navigate = useNavigate();
    const { state = {} } = useLocation();
	const { captain_id, createdAt, fromQR } = state || {}

	const [team, setTeam] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const items = useMemo(() => {
		if(team)
		{
			return [
				{
					key: '1',
					label: 'ID',
					children: team.id,
				},
				{
					key: '2',
					label: 'Название',
					children: team.title,
				},
				{
					key: '3',
					label: 'Допущена',
					children: team.approved != null ? (team.approved == 1 ? <span style={{ color: "green" }}>Да</span> : <span style={{ color: "red" }}>Проверена/пересмотр</span>) : <span style={{ color: "red" }}>Не проверена</span>,
				},
                {
					key: '4',
					label: 'Проверил',
					children: <a target="_blank" rel="noopener noreferrer" href={"tg://user?id=" + team.approvedBy}>Telegram</a>,
				},
                {
					key: '4',
					label: 'Дата проверки',
					children: dateFormat(team.approvedDate, "dd.mm.yyyy HH:MM:ss"),
				},
			]
		}
		else
			return null;
	}, [team])

	useEffect(() => {
		const fetchData = () => {
			let params;
			if(fromQR)
			{
				params = {
					id: id,
					captain_id: captain_id,
					createdAt: createdAt,
					fromQR: 1
				}
			}
			else
			{
				params = {
					id: id,
					fromQR: 0
				}		
			}
			axios.get("/team", { params: params })
			.then((res) => {
				const data = res.data;
				setTeam(data.team);
				setIsLoading(false);
			})
			.catch((error) => {
				console.log(error)
				showPopup({
					message: error.response.data.error.message
				})
			})
		}
		fetchData()
	}, [])

	return (
		<div>
			<ConfigProvider
				theme={
					themeParams.text_color
					? {
						algorithm:
							colorScheme === 'dark'
							? theme.darkAlgorithm
							: theme.defaultAlgorithm,
						token: {
							colorText: themeParams.text_color,
							colorPrimary: themeParams.button_color,
							colorBgBase: themeParams.bg_color,
                            colorBgContainer: themeParams.bg_color
						},
						}
					: undefined
				}
			>
				{isLoading && <Skeleton active />}
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    {items && <Descriptions items={items} bordered size='medium'/>}
                    {team && <Table columns={columns(id)} dataSource={team.members} pagination={false} loading={isLoading} rowKey={(record) => {
                        return "member_" + record.id + "_" + record.createdAt
                    }}/>}
                </Space>
				<BackButton onClick={() => navigate(-1)} />;
			</ConfigProvider>
		</div>
	);
}

export default Team;
