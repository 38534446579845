import { Route, Routes } from "react-router-dom"
import { useShowPopup, useInitData } from '@vkruglikov/react-telegram-web-app';
import { Spin } from 'antd';
import React, { useEffect, useState } from 'react'
import axios from "axios";

import Main from "./Main"
import User from "./Member"
import MemberLog from "./MemberLog";
import Team from "./Team";

export default function App() {
	const [_, initData] = useInitData();
	const [loading, setLoading] = useState(true);
	const [isAllowed, setIsAllowed] = useState(false);
	const showPopup = useShowPopup();
	useEffect(() => {
		const fetchData = () => {
			axios.get("/auth")
			.then((res) => {
				console.log(res)
				if(res.status === 200)
				{
					setLoading(false);
					setIsAllowed(true);
				}
			})
			.catch((error) => {
				setLoading(false);
				showPopup({
					message: JSON.stringify(error)
				})
			})
		}
		const registerAxiosGlobals = () => {
			axios.defaults.baseURL = process.env.REACT_APP_API_PATH;
			axios.defaults.headers.common['Authorization'] = initData;
			axios.defaults.headers.post['Content-Type'] = 'application/json';

			axios.interceptors.request.use(request => {
				return request;
			}, error => {
				console.log(error);
				return Promise.reject(error);
			});

			axios.interceptors.response.use(response => {
				return response;
			}, error => {
				console.log(error);
				return Promise.reject(error);
			});
		}
		registerAxiosGlobals();
		fetchData();
	}, [initData])


	if(loading) return <div className="contentWrapper"><Spin/></div>
	if(!isAllowed) return <div className="contentWrapper">Вы не авторизованы</div>
	return (
		<Routes>
			<Route path="/" element={<Main />} />
			<Route path="/member/:id" element={<User />} />
			<Route path="/team/:id" element={<Team />} />
			<Route path="/member/logs/:id" element={<MemberLog />} />
		</Routes>
	)
}